@import '../../global.scss';

.inputLabel {
  width: 10px !important;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}
.row {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex: 1;
  padding: 0px;
}

.totalRow {
  flex: 0.3;
  display: flex;
}

.userDetailsContainer {
  height: 55vh;
}

.userDetailsForm {
  height: 55vh;
}

.rolesColumn {
  margin-left: 10px;
}

.rolesContainer {
  overflow: auto;
}

.newUserButton {
  align-self: flex-end;
  bottom: 10px;
  margin-top: auto !important;
}

.submitButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cardHeader {
  width: 100%;
  background-color: #3b3888;
  color: white;
}

.searchUser {
  display: flex;
  align-self: center;
  width: 80%;
}

.totalCardContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cardContainer {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
  border: 0px;
}

.totalCardContentValue {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.continueButton {
  align-self: flex-end;
  margin: 10px !important;
}
