@import '../../global.scss';

.header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding-bottom: 10px;
  box-shadow: none !important;
  color: rgba(59, 56, 136, 1) !important;
}
body {
  display: flex;
  flex: 1;
  padding: 0px;
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.container {
  display: flex;
  flex: 1;
  padding: 0px;
}

.table {
  min-width: 700;
}
.text {
  display: flex;
  margin: 5px;
}

.printable {
  display: none;
}

@media print {
  .printable {
    display: flex;
    margin: 5px;
  }
}

.headerText {
  display: flex;
}

.root {
  width: '100%';
  max-width: 360;
}

.tableHeader {
  background-color: rgba(214, 210, 210, 0.658);
}

.alertButton {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.row {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}

.cardHeader {
  width: 100%;
  background-color: #3b3888;
  color: white;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}

.inputField {
  margin-bottom: 10px !important;
  width: 100%;
}

.totalRow {
  flex: 0.3;
  display: flex;
}

.totalCardContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.totalCardContentValue {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.continueButton {
  align-self: flex-end;
  margin: 10px !important;
}

.Buttons {
  align-self: flex-end;
  margin-top: auto !important;
  display: flex;
}

.button {
  margin: 10px;
}

.cancelButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // align-items: flex-end;
}

.ccInputRow {
  margin-bottom: 0px;
}

@media only screen and (max-width: 650px) {
  .container {
    height: 85vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .saveButton {
    display: block;
    flex: 1;
    position: inherit;
    right: auto;
    top: auto;
  }

  .columnRight {
    margin-left: 0px;
  }
}
