@import "../../global.scss";

.loginContainer {
  background-image: url(../../static/FP-MerchantDashboard-Background-2.png);

  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: auto;
}

.headerOptions {
  display: flex;
  height: 100%;
  background: linear-gradient(270deg, rgba(56, 56, 137, 0.5) 0%, rgba(222, 44, 48, 0.5) 100%);
}

.loginCard {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 10px;
  margin-top: 10vh;
  margin: auto;
  height: auto;
}

.formTextInput {
  width: 350px;
}

.logo {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}


.inputField {
  display: flex;
  margin: 5px 10px 10px 10px!important;
  width: 94%;
}
.inputFieldSelect {
  display: flex;
  margin: 5px 10px !important;
  align-self: center;
  width: 90%;
}
.buttonRow {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.loginViewButtonDiv {
  display: flex;
  // width: 60%;
  // align-self: center;
  
}
.inputFieldDiv {
  margin-top: 20px;
}

.inputErrorAlert {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin: 10px !important
}

 
.loginViewButton {
  color: white !important;
  width: 60% !important;
}

.headerTitle {
  display: flex;
  align-self: center;
  color: #3b3888;
}

