.errorModal {
  background: white;
  left: 50% !important;
  margin-left: -175px !important;
  top: 50% !important;
  margin-top: -127px !important;
  bottom: auto !important;
  right: auto !important;
  z-index: 99999 !important;
  display: flex;
}

.errorModal:after {
  background-color: black;
  opacity: 0.5;
}

.errorsContainer {
  display: flex;
  flex-direction: row;

  width: 400px;
  height: 300px;
  padding-right: 40px;
  padding-bottom: 30px;
}

.logoContainer {
  flex: 0.2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding-left: 40px;
}

.errorIcon {
  width: 80px;
  margin-top: 40px;
  fill: #e32c2d;
  color: #8f0018;
}

.detailsAndButtonsContainer {
  flex: 0.8;
}

.errorDetailsContainer {
  overflow-x: wrap;
  overflow-y: hidden;
  height: 115px;
}

.errorTitle {
  padding-top: 40px;
  font-size: 13px;
  font-weight: bold;
}

.errorText {
  padding-top: 10px;
  font-size: 13px;
}

.confirmButton {
  float: right;
  padding-right: 30px;
}
