@import 'global.scss';

.contentContainer {
  position: relative;
  padding: 30px 10px 10px 10px;
  min-height: 87vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  height: 45px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

.logoDrawer {
  display: none;
}

.subHeader {
  background-color: #eceeef !important;
  text-align: center;
  font-size: 1.1rem !important;
}

.userMenu {
  margin-left: auto;
  display: flex;
  justify-content: right;
  flex-direction: row;
  width: 200px;
}

.userContainerLeft {
  display: flex;
  flex: 0.75;
  flex-direction: column;
  padding-top: 10px;
  align-content: center;
}

.userContainerRight {
  display: flex;
  flex: 0.25;
  justify-content: center;
}

.userMenuIcon {
  font-size: xxx-large;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: 80px;
}

.userMenuContainer {
  position: absolute;
  z-index: 1000;
  top: 85px;
  right: 0px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8fafb;
  box-shadow: 3px 3px 10px rgb(170, 169, 169);
  color: #3b3888;
}

.listItem {
  padding: 0px !important;
}

.menuItem {
  padding-top: 0px !important;
}

.icon {
  min-width: 40px !important;
  color: #3b3888 !important;
}

.userMenuItem {
  cursor: pointer;
  color: black;
}

.userMenuUserInfo {
  display: none;
}

.active {
  background-color: white !important;
  color: transparent;
}
.listMenuItems {
  padding-top: 0px !important;
}

.active * {
  color: #e32c2d;
}

.activeOrganizationContainer {
  padding: 30px 15px;
  background: rgba(196, 196, 196, 0.2);
  color: white;
}
.userMenuIcon {
  color: white !important;
  margin-top: auto;
  margin-bottom: auto;
}
@media only screen and (max-width: 1199px) {
  .contentContainer {
    position: relative;
    min-height: 87vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 10px 10px 10px;
  }

  .logo {
    display: none;
  }
  .logoDrawer {
    display: block;
    height: 45px;
    margin: 1.5rem auto;
  }

  .userMenuUserInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .userContainerLeft {
    display: none;
  }

  .userMenu {
    width: 40px;
  }
}
