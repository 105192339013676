//@import "../../../../global.scss";

.header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  padding-bottom: 10px;
  box-shadow: none !important;
  color: rgba(59, 56, 136, 1) !important;
}
.titleHeader {
  color: #3b3888 !important;
}
.gridContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.container {
  display: flex;
  flex: 1;
  padding: 0px;
}

.row {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}

.cardHeader {
  width: 100%;
  background-color: #3b3888;
  color: white;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.inputFieldSelect {
  width: 30%;
  margin: 20px !important;
}

.columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}

.inputField {
  margin-bottom: 10px !important;
  width: 100%;
}

.totalRow {
  flex: 0.3;
  display: flex;
}

.totalCardContent {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.totalCardContentValue {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.continueButton {
  align-self: flex-end;
  margin-top: auto !important;
}

  .dateInput {
  margin: 10px !important;
}
.dateContainer {
  display: flex;
  // flex-direction: column;
}

.ccInputRow {
  margin-bottom: 0px;
}

@media only screen and (max-width: 650px) {
  .container {
    height: 85vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .saveButton {
    display: block;
    flex: 1;
    position: inherit;
    right: auto;
    top: auto;
  }

  .columnRight {
    margin-left: 0px;
  }
}
