.container {
  background: linear-gradient(347.61deg, #383889 4.6%, #a33050 42.4%, #de2c30 92.69%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logo {
  height: 60px;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}
.continuePrimary {
  width: 90%;
  height: 42px;
  left: 46px;
  top: 574px;
  color: white;
  background: #3b3888;
  border: none;
  border-radius: 4px;
  margin: 0.5rem 0px;
}
