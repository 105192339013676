@import "../../global.scss";

.loginContainer {
  // background: rgb(229,44,44);
  background-image: url(../../static/FP-MerchantDashboard-Background-2.png);
  // background: var(--login-background);

  /* Full height */
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.headerOptions {
  display: flex;
  height: 100%;
  background: linear-gradient(270deg, rgba(56, 56, 137, 0.5) 0%, rgba(222, 44, 48, 0.5) 100%);
}

.loginCard {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 10px;
  margin-top: 10vh;
  margin: auto;
  height: auto;
}

.formTextInput {
  width: 350px;
}

.logo {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.buttonRow {
  display: flex;
  justify-content: space-evenly;
  margin-top: 55px;
}

.forgotPasswordViewButton {
  color: white !important;
}

.forgotPasswordViewLink {
  color: #e32c2d !important;
  margin-top: 5px;
}

.formRow {
  margin-left: 10px;
}

.headerTitle {
  display: flex;
  align-self: center;
  color: #3b3888;
}
