@import "../../global.scss";

.gridContainer {
  height: 80vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rowTitle {
  display: inline-block;
  margin-right: 10px;
  width: 70px;
  font-weight: bold;
}

.continueButton {
  align-self: flex-end;
  margin: 10px !important;
}


.rowBadge {
  margin-left: 10px;
  margin-right: 20px;
}

.claimAllButton {
  margin-left: 30px;
  background-color: var("--color-1");
}

[class*="ContainerBase-wrapper"] {
  width: 100%;
}

.groupByRowContainer {
  width: 100%;
}

.groupByLocationName {
  margin-left: 20px;
}
