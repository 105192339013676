.images {
  display: flex;
  width: 90%;
  margin: 2px 0px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 150%;
}

.inputAccept {
  margin-top: 2rem;
  width: 90%;
  align-self: center;
}

.inputAccept {
  font: open-sans;
  font-size: 15px;
  color: #3b3888;
  width: 90%;
}

.inputAcceptSpan {
  font: open-sans;
  font-size: 11px;
  color: #3b3888;
  width: 90%;
}
