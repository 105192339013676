@import "../../../../global.scss";

.userListRow {
  padding: 0px !important;
  text-align: center;
}

.userListRow:hover {
  background-color: var(--color-3);
}

.componentContainer {
  height: 80vh;
  overflow: auto;
  width: 100%;
}

.searchUser {
  display: flex;
  align-self:flex-end;
  width: 30%;
  margin: 10px;
}