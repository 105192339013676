@import "../../global.scss";


.Buttons {
  align-self: flex-end;
  margin-top: auto !important;
  display: flex;
}

.button {
  margin: 5px;
}

.tableHeader {
  background-color: #ECEEEF;
}