.header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.container {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 10px;
}

.cardContainer {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
}

.row {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}

.cardHeader {
  width: 100%;
  background-color: #3b3888;
  color: white;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}

.inputField {
  margin: 10px !important;
}

.inputFieldSelect {
  display: flex;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  align-self: center;
  width: 90%;
}

.inputFieldDiv {
  display: inline-block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  align-self: center;
}

.totalRow {
  flex: 0.3;
  display: flex;
}

.totalCardContent {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-self: center;
  margin: 10px;
}

.totalCardContentValue {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.continueButton {
  align-self: flex-start;
  margin-top: auto !important;
  margin: 10px;
}

.addCitationButton {
  align-self: flex-start;
  background: none;
  border: none;
  margin: 10px;
  padding: 0;
  cursor: pointer;
}
.addCitationButton:hover {
  color: rgba(229, 44, 44, 1);
}

.deleteButton {
  align-self: flex-end;
  background: none;
  border: none;
  margin: 10px;
  cursor: pointer;
}

.ccInputRow {
  margin-bottom: 0px;
}

.root {
  display: flex;
}

@media only screen and (max-width: 650px) {
  .container {
    height: 85vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .saveButton {
    display: block;
    flex: 1;
    position: inherit;
    right: auto;
    top: auto;
  }

  .columnRight {
    margin-left: 0px;
  }
}
