.containerButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.continuePrimary {
  width: 90%;
  height: 42px;
  left: 46px;
  top: 574px;
  color: white;
  background: #3b3888;
  border: none;
  border-radius: 4px;
  margin: 0.5rem 0px;
}

.continuePrimaryDisabled {
  width: 90%;
  height: 42px;
  left: 46px;
  top: 574px;
  color: white;
  background: #ccc;
  border: none;
  border-radius: 4px;
  margin: 0.5rem 0px;
}
.backPrimary {
  width: 60%;
  height: 42px;
  left: 46px;
  top: 574px;
  color: white;
  background: #ccc;
  border: none;
  border-radius: 4px;
  margin: 0.5rem 0px;
}

.form {
  width: 100%;
  padding: 1rem;
}

.loading {
  margin: 1rem;
}
