@import "../../../../global.scss";

.filterCell {
  width: 100%;
  display: flex;
  align-items: center;
}

.filterDateInput {
  font-size: 14px;
  margin-right: 10px;
  width: 70%;
}

.selectFilterCombo {
  width: 100%;
}
