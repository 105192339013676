@import "../../../../global.scss";

.root {
  width: '100%';
  max-width: 360;
}

.cardHeader {
  background-color: rgba(214, 210, 210, 0.658);
}

.cardContainer {
  margin: 10px;
  width: 100%;
  background-color: #f8fafb !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
  border: 0px;
  box-shadow: none !important;
  background: #f8fafb;
  border-radius: 4px;
}