@import-normalize;

* {
  font-family: -apple-system, 'Open Sans', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

body {
  background-color: white !important;
  height: 100vh;
  width: 100%;
  margin: 0;
}

.floatRight {
  float: right;
}

#root {
  width: 100%;
}

div.srv-validation-message {
  color: #e32c2d;
  padding-bottom: 5px;
}

.cardContainer {
  width: 100%;
  background-color: #f8fafb !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 0px;
  border: 0px;
  box-shadow: none !important;
  background: #f8fafb;
  border-radius: 4px;
}

.scrollableCardContainer {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #0000001f;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: scroll !important;
  padding-right: 15px;
  padding-left: 15px;
}

.cardTitle {
  font-size: 18px;
  color: #333333;
  margin-bottom: 20px;
  margin-top: 15px;
}

.cardCollectionTitle {
  font-size: 18px;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 5px;
  text-decoration: underline;
}

.cardDetailTitle {
  font-size: 1.3vh;
  color: #707070;
  margin-bottom: 0.2vh;
}

.cardDetailText {
  font-size: 1.5vh;
  color: #333333;
}

.cardDetailTitle,
.cardDetailText {
  margin-left: 15px;
}

.detailViewTitleContainer {
  height: 65px;
  padding-top: 20px;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.detailViewTitle {
  font-size: 18px;
  color: #333333;
  letter-spacing: 0.29px;
  margin-left: 15px;
  font-weight: bold;
}

.verticalScollable {
  overflow-y: scroll !important;
}
