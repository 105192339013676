@import '../../../global.scss';
$primary: #06357a;
$border: #dbe1e8;

.header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $border;
  box-shadow: none !important;
}
.header h3 {
  font-weight: 300;
  color: $primary;
  margin: 2rem;
  color: rgba(59, 56, 136, 1);
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.headerText {
  display: flex;
  align-self: center;
  background-color: rgba(248, 250, 251, 1);
  color: rgba(59, 56, 136, 1);
}

.headerText p {
  font-size: 1.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  box-shadow: none !important;
}

.table {
  width: 100%;
  margin: auto;
}

.tableHeader {
  background-color: rgba(214, 210, 210, 0.658);
}

.cardContainer {
  margin-bottom: 20px;
  // padding: 2%;
}

.subCardContainer {
  width: 90%;
  background-color: #f8fafb !important;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 0px 0px 0px 0px;
  border: 0px;
  box-shadow: none !important;
  background: #f8fafb;
  border-radius: 4px;
  margin-bottom: 20px;
}

.cardHeader {
  width: 100%;
  background-color: rgba(248, 250, 251, 1);
  color: rgba(59, 56, 136, 1);
  text-align: center;
}

.continueButton {
  align-self: flex-end;
}

.cancelButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // align-items: flex-end;
}

.text {
  display: flex;
  align-self: center;
  font-weight: bold;
  color: $primary;
  padding: 1rem;
}

.Buttons {
  align-self: center;
  margin-top: auto !important;
  display: flex;
}

.button {
  margin: 10px;
}

@media only screen and (max-width: 650px) {
  .container {
    height: 85vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }
  .header h1 {
    font-weight: 300;
    color: $primary;
    margin: 2rem;
  }

  .saveButton {
    display: block;
    flex: 1;
    position: inherit;
    right: auto;
    top: auto;
  }

  .columnRight {
    margin-left: 0px;
  }
}
