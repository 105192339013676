.header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  color: rgba(59, 56, 136, 1);
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.container {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 10px;
  box-shadow: none !important;
  // background-color:  rgba(236, 238, 239, 1);
}

.textField {
  display: flex;
  margin: 10px !important;
  flex-direction: column;
}

.liveButton {
  align-self: flex-end;
  margin-top: auto !important;
  margin: 10px;
}

.IconButton {
  background-color: #2e8b57;
}

.row {
  display: flex;
  flex: 1;
  margin-bottom: 10px;
}

.cardHeader {
  width: 100%;
  background-color: rgba(248, 250, 251, 1);
  color: rgba(59, 56, 136, 1);
  text-align: left;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}

.dateContainer {
  display: flex;
  // flex-direction: column;
}

.dateInput {
  margin: 10px !important;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: 25px !important;
}
.avatar{
  background-color: 'green';
  width: 50px;
  height: 35px;
}

.avatarText {
  margin: 10px !important;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
}
.percentage {
  font-size: 18 px;
  color:rgba(59, 56, 136, 1);
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-weight: bold;

}

.percentageText {
  flex-direction: column;
  align-self: center;
  text-align: center;

}

@media only screen and (max-width: 650px) {
  .container {
    height: 85vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px;
  }

  .header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .saveButton {
    display: block;
    flex: 1;
    position: inherit;
    right: auto;
    top: auto;
  }

  .columnRight {
    margin-left: 0px;
  }
}
