@import '../../../global.scss';
$primary: #06357a;
$border: #dbe1e8;
$success: #00b212;
$background: #eceeef;
$backgroundLigth: #f8fafb;

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

h3 {
  color: $primary;
  font-weight: bold;
}

.cardContainer {
  background: white !important;
}

.saveButton {
  position: absolute;
  right: 25px;
  top: 15px;
}

.alertButton {
  margin: 10px;
  width: 100%;
  color: $success;
}
.alertButton * {
  font-weight: bold;
  color: $success;
}

.cardData {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: auto;
  margin: 10px;
  align-items: center;
}
.cardData .header {
  background: $background;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  font-weight: bold;
  padding: 5px;
}
.cardData .body {
  background-color: $backgroundLigth;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  padding: 10px;
}
.cardData .item {
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 5px;
}
.cardData .title {
  font-weight: bold;
  margin-right: 10px;
}
