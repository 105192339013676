@import '../../../../global.scss';

.Buttons {
  align-self: flex-end;
  margin-top: auto !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  margin: 0px 10px;
  background: #3b3888;
  border-radius: 4px;
}
