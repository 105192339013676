@import '../../global.scss';

.grid {
  height: 100%;
}

.rowTitle {
  margin-right: 10px;
}

.cardHeader {
  width: 100%;
  background-color: #3b3888;
  color: white;
  border-radius: 4px;
}

.cardHeader * {
  color: white;
}

.rowBadge {
  margin-right: 10px;
}

.groupByNavButton {
  float: right;
  margin-right: 20px;
  z-index: 9999;
}

th > div {
  text-align: center;
}

.paperWrapper {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  border: 0px;
  box-shadow: none !important;
}

.paperWrapper > div:first-of-type {
  height: inherit;
  padding-bottom: 10px;
}

.gridRoot {
  flex: 1;
}

.filterCell {
  width: 100%;
  display: flex;
  align-items: center;
}

.filterDateInput {
  font-size: 14px;
  margin-right: 10px;
  width: 70%;
}

.gridTitle {
  font-size: 18px;
  color: white;
}

[class*='ContainerBase-wrapper'] {
  width: 100%;
}

.searchInput {
  color: white;
  border-bottom: none;
}

.addButton {
  cursor: pointer;
  color: white;
  font-size: 21px;
  margin-left: 5px;
}

.gridHeaderRow {
  display: flex;
}

.resetButton {
  margin-left: 10px;
}

.selectFilterCombo {
  width: 100%;
}

.loadingIndicator {
  margin-left: 20px;
}

.addCitationButton {
  align-self: flex-start;
  background: none;
  border: none;
  margin: 10px;
  padding: 0;
  cursor: pointer;
  color: rgba(229, 44, 44, 1);
}
