@import '../../../global.scss';

.loginContainer {
  // background: rgb(229,44,44);
  background-image: url(../../../static/FP-MerchantDashboard-Background-2.png);
  // background: var(--login-background);

  /* Full height */
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.headerOptions {
  display: flex;
  height: 100%;
  background: linear-gradient(270deg, rgba(56, 56, 137, 0.5) 0%, rgba(222, 44, 48, 0.5) 100%);
}

.loginCard {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 10px;
  margin-top: 10vh;
  margin: auto;
  height: auto;
}

.formTextInput {
  width: 350px;
}

.inputForm {
  margin-top: 1rem;
}

.inputField {
  display: flex;
  margin: 0px auto !important;
  width: 90%;
  height: 39px !important;
}

.inputField input {
  height: 1px !important;
}

.logo {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #3b3888;
  margin: auto;
}

.formRow {
  margin-bottom: 20px;
  margin-left: 10px;
}

.buttonRow {
  display: flex;
  justify-content: space-evenly;
}

.loginViewButton {
  // background-color: #2B3990 !important;
  color: white !important;
  width: 60%;
}

.forgotRow {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0.5rem 1rem;
}
.forgotPassword {
  color: #e32c2d;
}
