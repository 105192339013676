$background: #06357a;

.layout {
  background-color: #06357a;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  background-repeat: round;
  background-size: cover;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-image: url(../../static/FP-MerchantDashboard-Background-2.png);
  height: 210px;
  width: 100%;
}

.phone {
  font-weight:bolder 
}


.headerOptions {
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(56, 56, 137, 0.5) 0%, rgba(222, 44, 48, 0.5) 100%);
}

.headerInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  padding: 1rem;
}

.headerInfo > p {
  margin: 0;
}

.titleCourt {
  font-size: 2rem;
}
.options {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;
  align-items: center;
}

.alert {
  display: flex;
  height: 100px;
  width: 100%;
  background-color: #e32c2d;
  color: white;
  padding: 20px;
}
.footer {
  display: flex;
  background-color: white;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.footer img {
  margin: 1rem;
}

.footer .rigth {
  display: flex;
  align-items: center;
}

.footer .credibilityLogo {
  float: right;
}
